import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

const Buttons = {
  true: {
    className: 'btn-outline-primary',
    label: 'PAID',
  },
  false: {
    className: 'btn-outline-danger',
    label: 'UNPAID',
  },
};

class PaymentStatusToggle extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      paid: this.props.paid,
      buttonClassName: Buttons[this.props.paid].className,
      label: Buttons[this.props.paid].label,
      updating: false,
    };
  }

  render() {
    if (this.props.paidByPayPal) {
      return (
        <img
          src="https://www.paypalobjects.com/webstatic/en_US/i/buttons/pp-acceptance-medium.png"
          alt="Paid by PayPal"
        />
      );
    }
    var opposite = !this.props.paid;
    return (
      <a
        className={`btn ${this.state.buttonClassName} btn-sm toggle-paid`}
        href="#"
        onMouseEnter={() => {
          this.onMouseEnter();
        }}
        onMouseLeave={() => {
          this.onMouseLeave();
        }}
        onClick={e => {
          this.handleClick(e);
        }}
      >
        {this.state.updating ? this.spinner() : this.state.label}
      </a>
    );
  }

  spinner() {
    return <FontAwesomeIcon icon={faSpinner} spin />;
  }

  buttonSpec() {
    return Buttons[this.state.paid];
  }

  oppositeButtonSpec() {
    return Buttons[!this.state.paid];
  }

  updateButton(spec = this.buttonSpec()) {
    this.setState({
      buttonClassName: spec.className,
      label: spec.label,
    });
  }

  onMouseEnter() {
    this.updateButton(this.oppositeButtonSpec());
  }

  onMouseLeave() {
    this.updateButton(this.buttonSpec());
  }

  handleClick(e) {
    e.preventDefault();
    if (
      !window.confirm(
        `Mark this invoice as ${this.oppositeButtonSpec().label}?`
      )
    ) {
      return;
    }
    var oldPaid = this.state.paid;
    var newPaid = !this.state.paid;
    this.setState({ paid: newPaid, updating: true });
    var data = {
      authenticity_token: this.props.authenticityToken,
      invoice: { paid: newPaid },
    };
    fetch(this.props.invoiceUrl, {
      method: 'PATCH',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(response => {
        return response.json();
      })
      .then(json => {
        this.setState({ paid: json.invoice.paid, updating: false });
        this.updateButton();
      })
      .catch(error => {
        this.setState({ paid: oldPaid, updating: false });
        this.updateButton();
        alert("Unable to save changes to this invoice.");
      });
  }
}

PaymentStatusToggle.propTypes = {
  invoices: PropTypes.array,
};
export default PaymentStatusToggle;
