import React, { Component, Fragment } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSpinner,
  faExclamationTriangle,
} from '@fortawesome/free-solid-svg-icons';

class MonthToDateSampleCounts extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    props.imports.forEach(
      each => (this.state[each.id] = { count: '', errors: [] })
    );
  }

  componentDidMount() {
    const { imports } = this.props;
    imports.forEach(eachImport => this.fetchCount(eachImport));
  }

  fetchCount(eachImport) {
    const uri = `/count_report/month_to_date/${eachImport.id}`;
    fetch(uri).then(response => {
      if (response.ok) {
        response.json().then(body => {
          this.setState({ [eachImport.id]: body });
        });
      } else {
        this.setState({
          [eachImport.id]: { errors: [response.statusText] },
        });
      }
    });
  }

  total() {
    const { imports } = this.props;
    const result = imports.reduce((total, each) => {
      const importState = this.state[each.id];
      return (
        parseFloat(total) +
        parseFloat(importState && importState.count ? importState.count : 0)
      );
    }, 0);
    return result;
  }

  render() {
    const { imports } = this.props;
    return (
      <Fragment>
        {imports.map(each => {
          const state = this.state[each.id];
          return (
            <SampleCountLine
              key={each.id}
              name={each.name}
              count={state.count}
              errors={state.errors}
            />
          );
        })}
        <SampleCountLine
          key="total"
          className="total"
          name="Total"
          count={this.total()}
          errors={[]}
        />
      </Fragment>
    );
  }
}

class SampleCountLine extends Component {
  spinner() {
    return <FontAwesomeIcon icon={faSpinner} spin />;
  }

  errors() {
    const { errors } = this.props;
    if (errors.length === 0) return '';
    return (
      <div className="errors">
        {errors.map((each, index) => (
          <div key={index}>
            <FontAwesomeIcon icon={faExclamationTriangle} />
            &nbsp;
            {each}
          </div>
        ))}
      </div>
    );
  }

  render() {
    const { className, name, count, errors } = this.props;
    return (
      <div className={`row ${className}`}>
        <div className="name">{name}</div>
        <div className="count">
          {!count && !errors.length
            ? this.spinner()
            : count && parseFloat(count).toFixed(1)}
        </div>
        {this.errors()}
      </div>
    );
  }
}

export default MonthToDateSampleCounts;
