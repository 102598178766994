import React from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import InputWithValidation from '../InputWithValidation';
import Dropdown from '../Dropdown';
import './style.scss';
import { asUSCurrency } from '../../utils';

const InvoiceLine = props => {
  const {
    id,
    isShowingRecommendations,
    date,
    description,
    quantity,
    rate,
    equivalent_count,
    recommendations,
    amount,
    account_name,
    accountNames,
    taxable,
    onChange,
    onSelectRecommendation,
  } = props;
  return (
    <div className="row mb-2">
      <div className="col-12">
        <div className="row form-group mb-2">
          <div className="col-3">
            <DatePicker
              className="form-control form-control-sm"
              selected={date}
              onChange={value => onChange(id, 'date', value)}
            />
          </div>
          <div className="col-5">
            <Dropdown
              isShowing={isShowingRecommendations}
              menuItems={recommendations}
              onSelectItem={itemId => onSelectRecommendation(itemId, id)}
              onBlur={() => onChange(id, 'isShowingRecommendations', false)}
            >
              <InputWithValidation
                className="form-control form-control-sm"
                value={description}
                placeholder="Description"
                required
                invalidFeedback="Please enter a description"
                onChange={event =>
                  onChange(id, 'description', event.target.value)
                }
                onFocus={event =>
                  onChange(id, 'description', event.target.value)
                }
                onClick={event =>
                  onChange(id, 'description', event.target.value)
                }
              />
            </Dropdown>
          </div>
          <div className="col-2">
            <InputWithValidation
              className="form-control form-control-sm"
              type="number"
              placeholder="Quantity"
              value={quantity}
              step="any"
              required
              data-toggle="tooltip"
              data-placement="top"
              title="Quantity"
              invalidFeedback="Please enter a valid quantity"
              onChange={event => onChange(id, 'quantity', event.target.value)}
            />
          </div>
          <div className="col-2">
            <InputWithValidation
              className="form-control form-control-sm"
              type="number"
              placeholder="Rate"
              value={rate}
              step="any"
              required
              data-toggle="tooltip"
              data-placement="top"
              title="Rate"
              invalidFeedback="Please enter a valid rate"
              onChange={event => onChange(id, 'rate', event.target.value)}
            />
          </div>
        </div>
        <div className="row form-group">
          <div className="col-6">
            <select
              className="form-control form-control-sm"
              value={account_name}
              onChange={event =>
                onChange(id, 'account_name', event.target.value)
              }
            >
              {accountNames.map(name => (
                <option key={name}>{name}</option>
              ))}
            </select>
          </div>
          <div className="col-2">
            <div className="form-check">
              <input
                id={`taxable-${id}`}
                className="form-check-input"
                type="checkbox"
                checked={taxable}
                onChange={event => {
                  onChange(id, 'taxable', event.target.checked);
                }}
              />
              <label
                className="form-check-label text-dark"
                htmlFor={`taxable-${id}`}
              >
                Taxable
              </label>
            </div>
          </div>
          <label className="col-2 text-right">Count:</label>
          <div className="col-2">
            <InputWithValidation
              className="form-control form-control-sm"
              type="number"
              placeholder="Count"
              value={equivalent_count}
              step="any"
              invalidFeedback="Please enter a equivalent count"
              data-toggle="tooltip"
              data-placement="bottom"
              title="Equivalent Count"
              onChange={event => onChange(id, 'equivalent_count', event.target.value)}
            />
          </div>
          <div className="col-2">

          </div>
        </div>
      </div>
    </div>
  );
};

export default InvoiceLine;
