import React from 'react';

const InvoiceFooter = props => {
  return (
    <div className="row invoice-footer">
      <div className="col-12">
        <div className="row no-gutters border-top pt-3">
          <div className="col" />
          <div className="col-auto col-ml-auto">
            <button
              className="btn btn-outline-primary m-2"
              type="button"
              onClick={props.onCancel}
            >
              Cancel
            </button>
            <form method="post" action={props.deleteUrl} className="d-inline">
              <input type="hidden" name="_method" value="delete" />
              <input
                onClick={props.onDelete}
                value="Delete"
                type="submit"
                className="btn btn-danger m-2"
              />
              <input
                name="authenticity_token"
                type="hidden"
                value={$('meta[name="csrf-token"]').attr('content')}
              />
            </form>
            <button
              className="btn btn-secondary m-2"
              type="button"
              onClick={props.onSave}
            >
              Save
            </button>
            <button
              className="btn btn-primary m-2"
              type="button"
              onClick={props.onSend}
            >
              Send
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InvoiceFooter;
