import React from 'react';

const Flash = props => {
  const { isError, onDismiss, children } = props;
  return (
    <div
      className={['alert', 'alert-dismissible', 'fade', 'show']
        .concat(isError ? ['alert-danger'] : ['alert-success'])
        .join(' ')}
    >
      {children}
      <button
        type="button"
        className="close"
        aria-label="Close"
        onClick={onDismiss}
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  );
};

export default Flash;
