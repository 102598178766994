import React, { Component, Fragment } from 'react';
import Papa from 'papaparse';
import currency from 'currency.js';
import AddressBlock from '../AddressBlock';
import Col from './Col';
import FileInput from '../FileInput';
import Flash from '../Flash';
import FormGroup from './FormGroup';
import RadioButton from './RadioButton';
import Row from './Row';
import Selector from './Selector';
import './style.scss';

const USD = value => currency(value, { symbol: '$', precision: 2 });

const NEW_INVOICE = 'Create new invoice';
const ADD_TO_INVOICE = 'Add to un-sent invoice';
const IGNORE = 'Ignore these transactions';

const isInbound = row => row['Invoice Section'] === 'Inbound';
const isAdjustment = row =>
  row['Invoice Section'] === 'Adjustments & Other Charges';

const SenderInfo = ({ sender }) => {
  const adjustmentCount = sender.shipments.filter(isAdjustment).length;
  const shipmentCount = sender.shipments.length - adjustmentCount;
  const totalCharges = sender.shipments.reduce(
    (acc, s) =>
      USD(s['Billed Charge'])
        .add(acc)
        .format(true),
    0
  );
  const shipmentCountText =
    shipmentCount === 1
      ? `${shipmentCount} shipment`
      : `${shipmentCount} shipments`;

  const adjustmentCountText =
    adjustmentCount === 1
      ? `${adjustmentCount} adjustment`
      : `${adjustmentCount} adjustments`;

  return (
    <Fragment>
      <div className="card mb-2">
        <div className="card-body">
          <AddressBlock
            street1={sender.name}
            street2={sender.companyName}
            street3={sender.street}
            city={sender.city}
            state={sender.state}
            zip={sender.zip}
          />
        </div>
      </div>
      <div className="p-2 pl-4">
        {`${shipmentCountText}, ${adjustmentCountText}`}
      </div>
      <div className="p-2 pl-4">{totalCharges}</div>
    </Fragment>
  );
};

const CustomerSelector = ({
  customers,
  onChange,
  className,
  required,
  wasValidated,
  value,
}) => {
  return (
    <Row className={className}>
      <label className="col-md-2 col-form-label text-right">Customer:</label>
      <Selector
        className={`col-md-10`}
        value={value}
        onChange={evt =>
          onChange(
            customers.filter(c => String(c.id) === evt.target.value)[0] || {
              id: '',
            }
          )
        }
        options={customers.map(c => ({ id: c.id, value: c.name }))}
        required={required}
        wasValidated={wasValidated}
        placeHolder={'<Choose a customer>'}
        disableDefault={false}
      />
    </Row>
  );
};

class SenderRowControls extends Component {
  constructor(props) {
    super(props);

    const zip = z => (z ? z.split(' ')[0] : null);

    const customerMatch = props.customers.filter(
      c => zip(c.zip) === zip(props.sender.zip)
    )[0];

    this.state = {
      checked: NEW_INVOICE,
      selectedInvoice: { id: '' },
      selectedCustomer: customerMatch || { id: '' },
      shouldRevalidate: true,
    };
  }

  isValid() {
    const { checked, selectedInvoice, selectedCustomer } = this.state;
    if (checked === NEW_INVOICE && selectedCustomer.id) {
      return true;
    } else if (checked === ADD_TO_INVOICE && selectedInvoice.id) {
      return true;
    } else if (checked === IGNORE) {
      return true;
    }
    return false;
  }

  render() {
    const { customers, onApply } = this.props;
    const {
      checked,
      shouldRevalidate,
      selectedCustomer,
      selectedInvoice,
    } = this.state;
    const invoices = this.props.invoices.filter(
      i => !selectedCustomer.id || i.customer_id === selectedCustomer.id
    );
    return (
      <Fragment>
        <CustomerSelector
          className="mb-2"
          onChange={customer =>
            this.setState({
              selectedCustomer: customer,
              shouldRevalidate: true,
            })
          }
          value={selectedCustomer.id}
          required={true}
          wasValidated={
            !shouldRevalidate && checked === NEW_INVOICE && !this.isValid()
          }
          customers={customers}
        />
        <RadioButton
          onClick={() =>
            this.setState({ checked: NEW_INVOICE, shouldRevalidate: true })
          }
          isChecked={checked === NEW_INVOICE}
        >
          <input
            type="text"
            readOnly
            className={'form-control form-control-plaintext'}
            value={NEW_INVOICE}
          />
        </RadioButton>
        <RadioButton
          onClick={() =>
            this.setState({ checked: ADD_TO_INVOICE, shouldRevalidate: true })
          }
          isChecked={checked === ADD_TO_INVOICE}
        >
          <Row>
            <Col w={'auto'}>
              <input
                type="text"
                readOnly
                className={'form-control form-control-plaintext'}
                value={ADD_TO_INVOICE}
              />
            </Col>
            <div className="col-md">
              <Selector
                options={invoices.map(i => ({ id: i.id, value: i.number }))}
                onChange={evt =>
                  this.setState({
                    shouldRevalidate: true,
                    selectedInvoice: invoices.filter(
                      i => String(i.id) === evt.target.value
                    )[0] || { id: '' },
                  })
                }
                required={true}
                wasValidated={
                  !shouldRevalidate &&
                  checked === ADD_TO_INVOICE &&
                  !this.isValid()
                }
                value={selectedInvoice.id}
                placeHolder={'<Choose an invoice>'}
                disableDefault={false}
              />
            </div>
          </Row>
        </RadioButton>
        <RadioButton
          onClick={() =>
            this.setState({ checked: IGNORE, shouldRevalidate: true })
          }
          isChecked={checked === IGNORE}
        >
          <input
            type="text"
            readOnly
            className={'form-control form-control-plaintext'}
            value={IGNORE}
          />
        </RadioButton>
        <button
          type="button"
          className={'btn btn-primary float-right mb-2'}
          onClick={() =>
            this.isValid()
              ? onApply(this.state)
              : this.setState({ shouldRevalidate: false })
          }
        >
          Apply
        </button>
      </Fragment>
    );
  }
}

const SenderRow = ({ sender, customers, invoices, onApply }) => {
  return (
    <Row className="border-bottom mt-4">
      <Col w={4}>
        <SenderInfo sender={sender} />
      </Col>
      <Col w={8}>
        <SenderRowControls
          sender={sender}
          customers={customers}
          invoices={invoices}
          onApply={onApply}
        />
      </Col>
    </Row>
  );
};

const senderKey = shipment =>
  '' +
  (shipment['Sender Name'] || '') +
  (shipment['Sender Company Name'] || '') +
  (shipment['Sender Street'] || '') +
  (shipment['Sender City'] || '') +
  (shipment['Sender State'] || '') +
  (shipment['Sender Zip Code'] || '');

const groupBySender = shipments => {
  const senders = {};
  const getOrCreateSender = id => {
    if (!id) {
      return;
    }
    let sender = senders[id];
    if (sender) {
      return sender;
    } else {
      sender = {
        id,
        shipments: [],
      };
      senders[id] = sender;
      return sender;
    }
  };
  shipments.forEach(s => {
    const sender = getOrCreateSender(senderKey(s));
    if (!sender) {
      return;
    }
    sender.shipments.push(s);
  });

  return Object.keys(senders).map(id => {
    let sender = senders[id];
    const sample = sender.shipments[0];
    sender.name = sample['Sender Name'];
    sender.companyName = sample['Sender Company Name'];
    sender.street = sample['Sender Street'];
    sender.city = sample['Sender City'];
    sender.state = sample['Sender State'];
    sender.zip = sample['Sender Zip Code'];
    return sender;
  });
};

const error = (title, detail) => ({
  title,
  detail,
});

class UpsCsvProcessor extends Component {
  constructor(props) {
    super(props);

    this.state = {
      fileName: '',
      senders: [],
      headerFields: [],
      flashContent: null,
      invoices: props.invoices,
    };
  }

  stripNonHeaderLines(csv) {
    const lines = [];
    let didFindHeader = false;
    return new Promise((resolve, reject) => {
      Papa.parse(csv, {
        step: (results, parser) => {
          const current =
            (Array.isArray(results.data) && results.data[0]) || null;
          if (!Array.isArray(current)) {
            return;
          }
          if (current[0] === 'Account Number') {
            didFindHeader = true;
          }
          if (didFindHeader) {
            lines.push(current);
          }
        },
        complete: (results, parser) => {
          resolve(Papa.unparse(lines));
        },
      });
    });
  }

  parseCsv(csv) {
    if (!csv) {
      console.log('no csv found');
      this.setState({ fileName: '' });
      return;
    }
    this.setState({ fileName: csv.name });
    this.stripNonHeaderLines(csv).then(result =>
      Papa.parse(result, {
        header: true,
        complete: (results, file) => {
          const data = results.data.filter(
            d => isInbound(d) || isAdjustment(d)
          );
          this.setState({
            senders: groupBySender(data),
            headerFields: results.meta.fields,
          });
        },
        error: (error, file) => {
          console.log('error', error);
          console.log('file', file);
        },
      })
    );
  }

  apiAddShipments(customer, invoice, shipments, senderId) {
    const { addShipmentsUrl, unsentInvoicesUrl } = this.props;
    const { headerFields } = this.state;
    const Shipment = s => ({
      date: new Date(s['Pickup Date']),
      service: s['Service Level'],
      tracking_number: s['Tracking Number'],
      rate: currency(s['Billed Charge']),
    });

    const body = JSON.stringify({
      add_shipments: {
        customer_id: customer['id'] || null,
        invoice_id: invoice['id'] || null,
        shipments: shipments.filter(isInbound).map(Shipment),
        adjustments: shipments.filter(isAdjustment).map(Shipment),
        data: [headerFields].concat(
          shipments.map(s => headerFields.map(h => s[h]))
        ),
      },
    });

    fetch(addShipmentsUrl, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
      },
      body,
    })
      .then(response => response.json())
      .then(response => {
        if (response.errors) {
          this.flash(response.errors);
          return;
        }
        this.dismissSender(senderId);
      })
      .then(() => {
        return fetch(unsentInvoicesUrl, {
          method: 'GET',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
          },
        });
      })
      .then(response => response.json())
      .then(response => {
        this.setState({ invoices: response.data });
      })
      .catch(reason => {
        console.log(reason);
        if (reason instanceof Error) {
          this.flash([error(`${reason.name}: ${reason.message}`)]);
          return;
        }
        this.flash([error('Unexpected error occured')]);
      });
  }

  dismissSender(id) {
    this.setState(state => ({
      senders: state.senders.filter(s => s.id !== id),
    }));
  }

  handleApply(sender, data) {
    if (data.checked === IGNORE) {
      this.dismissSender(sender.id);
      return;
    }
    this.apiAddShipments(
      data.selectedCustomer,
      data.selectedInvoice,
      sender.shipments,
      sender.id
    );
  }

  dismissFlash() {
    this.setState({ flashContent: null });
  }

  flash(errors) {
    this.setState({
      flashContent:
        Array.isArray(errors) && errors.length ? (
          <Fragment>
            {errors.map((error, i) => (
              <div key={i}>
                {error.title}
                {Array.isArray(error.detail) ? (
                  <ul className="list-group pt-3">
                    {error.detail.map((message, i) => (
                      <li
                        key={i}
                        className="list-group-item list-group-item-danger"
                      >
                        {message}
                      </li>
                    ))}
                  </ul>
                ) : null}
              </div>
            ))}
          </Fragment>
        ) : null,
    });
  }

  render() {
    const { fileName, senders, flashContent, invoices } = this.state;
    const { customers } = this.props;
    return (
      <form>
        <Row>
          <Col>
            <h2>UPS Shipments</h2>
          </Col>
        </Row>
        <Row>
          {flashContent ? (
            <div className="fixed-top mt-7">
              <Flash isError={true} onDismiss={() => this.dismissFlash()}>
                {flashContent}
              </Flash>
            </div>
          ) : null}
        </Row>
        <Row>
          <Col>
            <FormGroup>
              <label className="col-md-2 col-form-label">
                Choose a CSV file:
              </label>
              <div className="col-md-10">
                <FileInput
                  onChange={evt => {
                    this.parseCsv(evt.target.files[0]);
                  }}
                  fileName={fileName}
                />
              </div>
            </FormGroup>
          </Col>
        </Row>
        {senders.map(s => (
          <SenderRow
            key={s.id}
            sender={s}
            customers={customers}
            invoices={invoices}
            onApply={data => this.handleApply(s, data)}
          />
        ))}
      </form>
    );
  }
}

export default UpsCsvProcessor;
