import React, { Component, Fragment } from 'react';
import InputWithValidation from '../InputWithValidation';
import { sortedBy } from '../../utils';
import scrollIntoView from 'scroll-into-view';
import _ from 'lodash';

class PricingMatrixEditor extends Component {
  constructor(props) {
    super(props);
    let costs = {};
    let markups = {}
    console.log(props.items);
    for(let item of props.items) {
      let row = _.find(props.prices, (r) => r.item.id == item.id);
      if (row) {
        costs[item.id] = this.formatMoney(row.cost);
        markups[item.id] = this.formatMoney(row.markup);
      } else {
        costs[item.id] = '';
        markups[item.id] = '';
      }
    }
    this.state = {
      validationClass: '',
      name: props.pricing_matrix.name,
      costs: costs,
      markups: markups
    };

    this.onSave = this.onSave.bind(this);
    this.onCancel = this.onCancel.bind(this);
  }

  showValidationFeedback() {
    this.setState({ validationClass: 'was-validated' });
  }

  hideValidationFeedback() {
    this.setState({ validationClass: '' });
  }

  formatMoneys(costs) {
    return costs.map(p => ({ ...p, price: this.formatMoney(p.price) }));
  }

  formatMoney(price) {
    return ((price === 0 || price) && Number(price).toFixed(2)) || '';
  }

  total(item) {
    var price = this.state.costs[item.id];
    var markup = this.state.markups[item.id];
    price = (price.length == 0) ? 0.0 : parseFloat(price);
    markup = (markup.length == 0) ? 0.0 : parseFloat(markup);
    return this.formatMoney(price + markup);
  }

  setCost(item, price) {
    let costs = Object.assign({}, this.state.costs);
    costs[item.id] = price;
    this.setState({costs: costs});
  }

  setMarkup(item, markup) {
    let markups = Object.assign({}, this.state.markups);
    markups[item.id] = markup;
    this.setState({markups: markups});
  }

  onCancel(event) {
    location = location.href
      .split('/')
      .slice(0, -2)
      .join('/');
  }

  onSave(event) {
    const isInvalid = $('input:invalid').length;
    if (isInvalid) {
      this.showValidationFeedback();
      scrollIntoView($('input:invalid')[0]);
      event.preventDefault();
      return;
    }
    this.hideValidationFeedback();
  }

  render() {
    return (
      <div className={this.state.validationClass} noValidate>
        <div className="form-group row mt-4">
          <label className="col-sm-2 col-form-label">Name:</label>
          <div className="col-sm-10">
            <InputWithValidation
              className="form-control"
              value={this.state.name}
              placeholder="Pricing matrix name"
              required
              name="pricing_matrix[name]"
              id="pricing_matrix_name"
              invalidFeedback="Please enter a name"
              onChange={event => this.setState({ name: event.target.value })}
            />
          </div>
        </div>
        <div className="form-group row">
          <div className="col">
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">Code</th>
                  <th scope="col">Name</th>
                  <th scope="col">Cost</th>
                  <th scope="col">Markup</th>
                  <th scope="col">Price</th>
                </tr>
              </thead>
              <tbody>
                {sortedBy(this.props.items, each => each.code).map(item => (
                  <tr key={item.id}>
                    <td>{item.code}</td>
                    <td>{item.name}</td>
                    <td>
                      <InputWithValidation
                        className="form-control"
                        type="number"
                        name={`costs[${item.id}]`}
                        value={this.state.costs[item.id]}
                        onChange={(event) => this.setCost(item, event.target.value)}
                        step="any"
                        prepend={
                          <div className="input-group-prepend">
                            <span className="input-group-text">$</span>
                          </div>
                        }
                        wrapperClass="input-group"
                        placeholder="Cost"
                        invalidFeedback="Please enter a valid price"
                      />
                    </td>
                    <td>
                      <InputWithValidation
                        className="form-control"
                        type="number"
                        name={`markups[${item.id}]`}
                        value={this.state.markups[item.id]}
                        onChange={(event) => this.setMarkup(item, event.target.value)}
                        step="any"
                        prepend={
                          <div className="input-group-prepend">
                            <span className="input-group-text">$</span>
                          </div>
                        }
                        wrapperClass="input-group"
                        placeholder="Markup"
                        invalidFeedback="Please enter a valid markup amount"
                      />
                    </td>
                    <td>${this.total(item)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div className="row">
              <div className="col" />
              <div className="col-auto col-ml-auto">
                <button
                  className="btn btn-outline-primary m-2"
                  name="commit"
                  type="button"
                  value="Cancel"
                  onClick={this.onCancel}
                >
                  Cancel
                </button>
                <button
                  className="btn btn-primary m-2"
                  name="commit"
                  type="submit"
                  value="Save"
                  onClick={this.onSave}
                >
                  Save
                </button>
                <a
                  className="btn btn-danger m-2"
                  data-confirm={`Are you sure you want to delete ${
                    this.props.pricing_matrix.name
                  }?`}
                  rel="nofollow"
                  data-method="delete"
                  href={`/pricing_matrices/${this.props.pricing_matrix.id}`}
                >
                  Delete
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default PricingMatrixEditor;
