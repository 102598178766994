import React from 'react';
import PropTypes from 'prop-types';
import PaymentStatusToggle from './PaymentStatusToggle';
import './style.scss';

class InvoiceList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchText: '',
      checkedInvoices: new Set(),
    };
  }

  handleCheckChange(invoice, checked) {
    if (checked) this.check(invoice);
    else this.uncheck(invoice);
  }

  check(invoice) {
    let checked = this.state.checkedInvoices;
    checked.add(invoice);
    this.setState({ checkedInvoices: checked });
  }

  uncheck(invoice) {
    let checked = this.state.checkedInvoices;
    checked.delete(invoice);
    this.setState({ checkedInvoices: checked });
  }

  isChecked(invoice) {
    return this.state.checkedInvoices.has(invoice);
  }

  confirmAndDeleteSelected(event) {
    if (!confirm(`Delete the selected ${this.selectedInvoiceText()}?`)) {
      event.preventDefault();
    }
  }

  confirmAndEmailSelected(event) {
    if (!confirm(`Email the selected ${this.selectedInvoiceText()}?`)) {
      event.preventDefault();
    }
  }

  selectedInvoiceText() {
    let pluralOrSingular =
      this.state.checkedInvoices.size == 1 ? 'invoice' : 'invoices';
    return `${this.state.checkedInvoices.size} ${pluralOrSingular}`;
  }

  onSearchKeyPress(event) {
    if (event.key !== 'Enter') return;
    event.preventDefault();
  }

  search(text) {
    this.setState({ searchText: text });
  }

  get filteredInvoices() {
    if (this.state.searchText.length == 0) return this.props.invoices;
    return this.props.invoices.filter(
      invoice =>
        invoice.customer.name
          .toLowerCase()
          .includes(this.state.searchText.toLowerCase()) ||
        invoice.number
          .toLowerCase()
          .includes(this.state.searchText.toLowerCase())
    );
  }

  render() {
    return (
      <React.Fragment>
        <div className="row mb-2">
          <div className="col-sm">
            <div className="input-group">
              <input
                type="search"
                className="form-control"
                onKeyPress={event => this.onSearchKeyPress(event)}
                onChange={event => this.search(event.target.value)}
                value={this.state.searchText}
              />
              <div className="input-group-append">
                <div className="input-group-text">
                  <i className="fa fa-search" />
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-auto text-right">
            <input
              type="submit"
              className="btn btn-danger mr-1"
              name="commit"
              value="Delete"
              data-toggle="tooltip"
              data-placement="top"
              data-original-title={`Delete ${this.selectedInvoiceText()}`}
              onClick={event => this.confirmAndDeleteSelected(event)}
              disabled={this.state.checkedInvoices.size == 0}
            />
            <input
              type="submit"
              className="btn btn-warning mr-1"
              name="commit"
              value="IIF"
              data-toggle="tooltip"
              data-placement="top"
              data-original-title={`Download IIF composed of ${this.selectedInvoiceText()}`}
              disabled={this.state.checkedInvoices.size == 0}
            />
            <input
              type="submit"
              className="btn btn-success mr-1"
              name="commit"
              value="Email"
              data-toggle="tooltip"
              data-placement="top"
              data-original-title={`Email ${this.selectedInvoiceText()}`}
              onClick={event => this.confirmAndEmailSelected(event)}
              disabled={this.state.checkedInvoices.size == 0}
            />
            <a href="/invoices/new" className="btn btn-primary">
              Create Invoice
            </a>
          </div>
        </div>
        {this.renderHiddenFields()}
        {this.renderInvoices()}
      </React.Fragment>
    );
  }

  renderHiddenFields() {
    let checked = Array.from(this.state.checkedInvoices);
    return checked.map(invoice => {
      return (
        <input
          key={invoice.id}
          type="hidden"
          name="selected_invoice_ids[]"
          value={invoice.id}
        />
      );
    });
  }

  renderInvoices() {
    if (this.filteredInvoices.length == 0) {
      return <div className="alert alert-warning">There are no invoices</div>;
    }
    return (
      <table className="table">
        <thead>
          <tr>
            <th />
            <th scope="col">Date</th>
            <th scope="col">Number</th>
            <th scope="col">Customer</th>
            <th scope="col" className="text-right">
              Total
            </th>
            <th scope="col">Sent</th>
            <th scope="col">Paid</th>
            <th scope="col" />
            <th />
          </tr>
        </thead>
        <tbody>
          {this.filteredInvoices.map(invoice => this.renderInvoiceRow(invoice))}
        </tbody>
      </table>
    );
  }

  renderInvoiceRow(invoice) {
    return (
      <tr key={invoice.id}>
        <td>
          <input
            type="checkbox"
            checked={this.isChecked(invoice)}
            onChange={event =>
              this.handleCheckChange(invoice, event.target.checked)
            }
          />
        </td>
        <td>{invoice.formatted_date}</td>
        <td>{invoice.number}</td>
        <td>{invoice.customer.name}</td>
        <td className="text-right">
          {Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
          }).format(invoice.total)}
        </td>
        <td>
          {invoice['sent?'] ? 'Yes' : invoice['enqueued?'] ? 'Sending' : ''}
        </td>
        <td>
          <PaymentStatusToggle
            authenticityToken={this.props['authenticity_token']}
            invoiceUrl={invoice['url']}
            paid={invoice['paid?']}
            paidByPayPal={invoice['paid_by_paypal?']}
          />
        </td>
        <th>
          <a
            className="btn btn-outline-primary btn-sm mr-1"
            href={`/invoices/${invoice.id}/edit`}
          >
            Edit
          </a>
          <a
            className="btn btn-secondary btn-sm mr-1"
            href={`/invoices/pdf/${invoice.id}`}
            data-turbolinks="false"
          >
            PDF
          </a>
          <a
            className="btn btn-warning btn-sm"
            href={`/invoices/iif/${invoice.id}`}
          >
            IIF
          </a>
        </th>
      </tr>
    );
  }
}

InvoiceList.propTypes = {
  invoices: PropTypes.array,
};
export default InvoiceList;
