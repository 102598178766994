export const asUSCurrency = number =>
  new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format(number);

export const sortedBy = (array, propOrExtractor) =>
  array.concat().sort((a, b) => {
    let extractFunc = obj => obj[propOrExtractor];
    if (typeof propOrExtractor === 'function') {
      extractFunc = propOrExtractor;
    }
    const A = extractFunc(a).toUpperCase();
    const B = extractFunc(b).toUpperCase();
    if (A < B) {
      return -1;
    }
    if (A > B) {
      return 1;
    }
    return 0;
  });

export default {
  asUSCurrency,
  sortedBy,
};
