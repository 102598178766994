import React from 'react';

const AddressBlock = ({
  street1,
  street2,
  street3,
  city,
  state,
  zip,
  showEmptyLines,
}) => {
  const cityState = [city, state].filter(Boolean).join(', ');
  const cityStateZip = [cityState, zip].filter(Boolean).join('  ');
  const addressLines = [street1, street2, street3, cityStateZip].filter(
    Boolean
  );

  return addressLines.length ? (
    <React.Fragment>
      {addressLines.map(line => (
        <div key={line}>{line}</div>
      ))}
    </React.Fragment>
  ) : showEmptyLines ? (
    <React.Fragment>
      <div>&nbsp;</div>
      <div>&nbsp;</div>
      <div>&nbsp;</div>
    </React.Fragment>
  ) : null;
};

export default AddressBlock;
