import React from 'react';
import AddressBlock from '../AddressBlock';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

// https://emailregex.com/
const VALID_EMAIL_REGEXP = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

class InvoiceHeader extends React.Component {
  constructor(props) {
    super(props);
    this.changeBccEmailString = this.changeBccEmailString.bind(this);
  }

  changeBccEmailString(event) {
    let bccEmailString = event.target.value;
    this.isValidBccEmailString(bccEmailString);
    if (!this.isValidBccEmailString(bccEmailString)) {
      this.refs.bccEmailString.setCustomValidity('invalid');
    } else {
      this.refs.bccEmailString.setCustomValidity('');
    }
    this.props.onBccEmailStringChange(event);
  }

  isValidBccEmailString(emailString) {
    if (emailString == null || emailString == '') return true;
    var emails = emailString.split(/\s*,?\s+/);
    for (let email of emails) {
      if (email != '' && !VALID_EMAIL_REGEXP.test(email)) {
        return false;
      }
    }
    return true;
  }

  render() {
    const { number, customer, date, onDateChange, bccEmailString } = this.props;
    return (
      <div className="row invoice-header mb-sm-2 was-validated">
        <div className="col">
          <div className="customer-name">
            <h2>{customer.name}</h2>
          </div>
          <AddressBlock {...customer} />
          {customer.emails.map(each => (
            <div>
              <a href={`mailto:${each}`} target="_blank">
                {each}
              </a>
            </div>
          ))}
          <div className="form-inline">
            <label className="mr-1">BCC:</label>
            <input
              type="text"
              ref="bccEmailString"
              className="form-control"
              style={{ width: '90%' }}
              onChange={this.changeBccEmailString}
              value={bccEmailString}
            />
            <div className="invalid-feedback ml-5">
              Provide valid emails seperated by spaces or commas.
            </div>
          </div>
        </div>
        <div className="col-auto col-ml-auto">
          <div className="row">
            <div className="col">
              <div>
                <strong>Invoice #:</strong>
              </div>
              <div>
                <strong>Date:</strong>
              </div>
            </div>
            <div className="col-auto col-ml-auto">
              <div>{number}</div>
              <div>
                <DatePicker selected={date} onChange={onDateChange} />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default InvoiceHeader;
