import React from 'react';

const RadioButton = ({ isChecked, onClick, children }) => (
  <div className={'row mb-2'} onClick={onClick}>
    <label className={'col-md-2 col-form-label text-right'}>
      <input type="radio" checked={isChecked} onChange={() => {}} />
    </label>
    <div className="col-md-10">{children}</div>
  </div>
);

export default RadioButton;
