import React from 'react';

const FileInput = ({ onChange, fileName }) => (
  <div className="custom-file">
    <input type="file" onChange={onChange} className="custom-file-input" />
    <label className="custom-file-label">
      {fileName ? fileName : `Choose file`}
    </label>
  </div>
);

export default FileInput;
