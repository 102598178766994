import React, { Component } from 'react';
import './style.scss';

class Dropdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeIndex: 0,
      didNavigate: false,
    };
  }

  handleKeyDown(e, menuItems) {
    switch (e.key) {
      case 'ArrowDown':
        this.arrowDown(menuItems);
        break;
      case 'ArrowUp':
        this.arrowUp(menuItems);
        break;
      case 'Enter':
        this.props.onSelectItem(menuItems[this.state.activeIndex].id);
        break;
      case 'Tab':
        this.tab(menuItems);
        break;
      case 'Escape':
        this.props.onBlur();
        break;
      default:
        this.reset();
        break;
    }
  }

  arrowDown(menuItems) {
    this.setState(state => ({
      activeIndex: (state.activeIndex + 1) % menuItems.length,
      didNavigate: true,
    }));
  }

  arrowUp(menuItems) {
    this.setState(state => ({
      activeIndex: (state.activeIndex - 1) % menuItems.length,
      didNavigate: true,
    }));
  }

  reset() {
    this.setState({ activeIndex: 0, didNavigate: false });
  }

  tab(menuItems) {
    const item = menuItems[this.state.activeIndex];
    if ((this.state.didNavigate && item) || menuItems.length === 1) {
      return this.props.onSelectItem(item.id);
    }
  }

  render() {
    const { children, isShowing, menuItems, onSelectItem, onBlur } = this.props;
    return (
      <div
        className={isShowing ? 'dropdown show' : 'dropdown'}
        onBlur={onBlur}
        onKeyDown={e => this.handleKeyDown(e, menuItems)}
        onFocus={() => this.reset()}
      >
        {children}
        <div className={isShowing ? 'dropdown-menu show' : 'dropdown-menu'}>
          {menuItems.map((item, index) => (
            <a
              className={
                index === this.state.activeIndex
                  ? 'dropdown-item active'
                  : 'dropdown-item'
              }
              key={item.id}
              onMouseDown={
                // use onMouseDown instead of onClick because if fires before
                // onBlur.
                () => onSelectItem(item.id)
              }
            >
              {item.text}
            </a>
          ))}
        </div>
      </div>
    );
  }
}

export default Dropdown;
