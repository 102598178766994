import React, { Component } from 'react';

class InputWithValidation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      validationClass: '',
    };
  }

  render() {
    const {
      invalidFeedback,
      wrapperClass,
      prepend,
      ...otherProps
    } = this.props;
    const classNames = [wrapperClass, this.state.validationClass]
      .filter(Boolean)
      .join(' ');
    return (
      <div
        className={classNames}
        noValidate
        onBlur={() => this.setState({ validationClass: 'was-validated' })}
      >
        {prepend}
        <input
          {...otherProps}
          onChange={event => {
            this.setState({ validationClass: 'was-validated' });
            if (otherProps.onChange) {
              otherProps.onChange(event);
            }
          }}
        />
        <div className="invalid-feedback">{invalidFeedback}</div>
      </div>
    );
  }
}

export default InputWithValidation;
