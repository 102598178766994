import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperclip } from '@fortawesome/free-solid-svg-icons';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { asUSCurrency } from '../../utils';

const InvoiceSummary = props => {
  const {
    attachments,
    rows,
    dueDate,
    taxRate,
    onAddLineGroup,
    onAddTopLevelLine,
    onChangeTaxRate,
    onDueDateChange,
  } = props;
  const amount = line =>
    line.type === 'group'
      ? Number(line.amount)
      : Number(line.rate) * Number(line.quantity);
  const subtotal = rows.reduce((acc, line) => amount(line) + acc, 0);
  const computeLineTax = line =>
    line.taxable ? (amount(line) * taxRate) / 100 : 0;
  const tax = rows.reduce((acc, line) => {
    const lineTax =
      line.type === 'group'
        ? line.lines.reduce(
            (linesAcc, current) => computeLineTax(current) + linesAcc,
            0
          )
        : computeLineTax(line);
    return acc + lineTax;
  }, 0);
  const total = subtotal + tax;
  return (
    <div className="row invoice-summary mt-4 px-4">
      <div className="col-lg-4">
        <div className="mb-3">
          <button
            type="button"
            className="btn btn-primary mr-2"
            onClick={onAddTopLevelLine}
          >
            +
          </button>
          Add line
        </div>
        <div className="mb-3">
          <button
            type="button"
            className="btn btn-primary mr-2"
            onClick={onAddLineGroup}
          >
            +
          </button>
          Add group
        </div>
        {
          // <div className="mb-3">
          //   <button type="button" className="btn btn-primary mr-2">
          //     +
          //   </button>
          //   Attach file
          // </div>
        }
        {attachments.map(item => (
          <div className="mt-4" key={item.filename}>
            <a href={item.url} target="_blank">
              <i className="p-2 mr-3">
                <FontAwesomeIcon icon={faPaperclip} />
              </i>
              {item.filename}
            </a>
          </div>
        ))}
      </div>
      <div className="col-lg-8">
        <form>
          <div className="form-group row mr-3">
            <div className="col-7" />
            <label className="col-2 col-form-label font-weight-bold text-right">
              Subtotal:
            </label>
            <div className="col-2">
              <input
                type="text"
                readOnly
                tabIndex="-1"
                className="form-control-plaintext text-right"
                value={asUSCurrency(Number(subtotal))}
              />
            </div>
          </div>
          <div className="form-group row mr-3">
            <div className="col-4" />
            <div className="col-3">
              <div className="input-group">
                <input
                  type="number"
                  className="form-control text-right"
                  value={taxRate}
                  onChange={event => onChangeTaxRate(event.target.value)}
                />
                <div className="input-group-append">
                  <div className="input-group-text">%</div>
                </div>
              </div>
            </div>
            <label className="col-2 col-form-label font-weight-bold text-right">Tax:</label>
            <div className="col-2">
              <input
                type="text  "
                readOnly
                tabIndex="-1"
                className="form-control-plaintext text-right"
                value={asUSCurrency(Number(tax))}
              />
            </div>
          </div>
          <div className="form-group row mr-3">
            <div className="col-7" />
            <label className="col-2 col-form-label font-weight-bold text-right">Total:</label>
            <div className="col-2">
              <input
                type="text"
                readOnly
                tabIndex="-1"
                className="form-control-plaintext text-right"
                value={asUSCurrency(Number(total))}
              />
            </div>
          </div>
          <div className="form-group row mr-3">
            <div className="col-7" />
            <label className="col-2 col-form-label font-weight-bold text-right">Due:</label>
            <div className="col-3">
              <DatePicker
                className="form-control"
                selected={dueDate}
                onChange={onDueDateChange}
              />
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default InvoiceSummary;
