import React from 'react';

const EquivalentCountTotal = props => {
  if (props.line.equivalent_count && props.line.quantity) {
    return (
      <small>
        Total: {(props.line.equivalent_count && props.line.quantity) ? (props.line.equivalent_count * props.line.quantity) : ''}
      </small>
    );
  } else {
    return null;
  }
};

export default EquivalentCountTotal;
