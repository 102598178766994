import React, { Component } from 'react';
import shortid from 'shortid';
import Flash from '../Flash';
import InvoiceHeader from './InvoiceHeader';
import InvoiceRows from './InvoiceRows';
import InvoiceSummary from './InvoiceSummary';
import InvoiceFooter from './InvoiceFooter';
import scrollIntoView from 'scroll-into-view';

class InvoiceEditor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      date: this.iso8601ToLocalDate(props.invoice.date),
      due_date: this.iso8601ToLocalDate(props.invoice.due_date),
      taxRate: this.initializeNumericInput(props.invoice.tax_percentage),
      bccEmailString: props.bccEmailString,
      rows: props.rows.map(row => {
        if (row.type === 'group') {
          return {
            ...row,
            lines:
              (Array.isArray(row.lines) &&
                row.lines.map(line => this.initializeLine(line))) ||
              [],
          };
        }
        return this.initializeLine(row);
      }),
      validationClass: '',
    };
    this.changeBccEmailString = this.changeBccEmailString.bind(this);
    this.changeDate = this.changeDate.bind(this);
    this.changeDueDate = this.changeDueDate.bind(this);
    this.deleteGroup = this.deleteGroup.bind(this);
    this.dismissFlash = this.dismissFlash.bind(this);
    this.setLineGroupAttribute = this.setLineGroupAttribute.bind(this);
    this.setLineAttribute = this.setLineAttribute.bind(this);
    this.addLineGroup = this.addLineGroup.bind(this);
    this.addLineToGroup = this.addLineToGroup.bind(this);
    this.addTopLevelLine = this.addTopLevelLine.bind(this);
    this.deleteLine = this.deleteLine.bind(this);
    this.changeTaxRate = this.changeTaxRate.bind(this);
    this.cancel = this.cancel.bind(this);
    this.send = this.send.bind(this);
    this.save = this.save.bind(this);
    this.formRef = React.createRef();
    this.getRecommendations = this.getRecommendations.bind(this);
    this.selectRecommendation = this.selectRecommendation.bind(this);
  }

  componentDidMount() {
    $('[data-toggle="tooltip"]').tooltip();
  }

  iso8601ToLocalDate(dateString) {
    const date = dateString ? dateString.replace(/-/g, '/') : Date.now();
    return new Date(date);
  }

  changeDate(date) {
    this.setState({ date: date });
  }

  changeDueDate(date) {
    this.setState({ due_date: date });
  }

  changeTaxRate(taxRate) {
    console.log(taxRate);
    this.setState({ taxRate: taxRate });
  }

  changeBccEmailString(event) {
    this.setState({
      bccEmailString: event.target.value,
    });
  }

  dismissFlash() {
    this.setState({ flashContent: null });
  }

  showValidationFeedback() {
    this.setState({ validationClass: 'was-validated' });
  }

  hideValidationFeedback() {
    this.setState({ validationClass: '' });
  }

  getRecommendations(value) {
    const re = new RegExp(value, 'i');
    return this.props.prices
      .map(p => {
        const text = `${p.item.code} - ${p.item.name}`;
        return re.test(text) ? { ...p, text } : null;
      })
      .filter(Boolean);
  }

  selectRecommendation(recId, rowId) {
    const recommendation = this.props.prices.reduce(
      (acc, curr) =>
        curr.id === recId
          ? {
              rate: curr.price,
              equivalent_count: curr.equivalent_count,
              description: curr.item.code,
              account_name: curr.item.account_name,
            }
          : acc,
      {}
    );
    this.setLineAttribute(rowId, 'description', recommendation.description);
    this.setLineAttribute(rowId, 'rate', recommendation.rate);
    this.setLineAttribute(
      rowId,
      'equivalent_count',
      recommendation.equivalent_count
    );
    this.setLineAttribute(rowId, 'account_name', recommendation.account_name);
  }

  flash(message, errors) {
    this.setState({
      flashContent: (
        <React.Fragment>
          {message}
          {Array.isArray(errors) && errors.length ? (
            <ul className="list-group pt-3">
              {errors.map(error => (
                <li className="list-group-item list-group-item-danger">
                  {error}
                </li>
              ))}
            </ul>
          ) : null}
        </React.Fragment>
      ),
    });
  }

  save() {
    const isValid = this.formRef.current.checkValidity();
    if (!isValid) {
      this.showValidationFeedback();
      scrollIntoView($('input:invalid, div.is-invalid')[0]);
      return;
    }
    this.hideValidationFeedback();

    fetch(`/api/invoices/${this.props.invoice.id}/update_and_flash`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
      },
      body: this.serializeInvoice(),
    })
      .then(response => response.json())
      .then(response => {
        if (response.status === 'success') {
          window.location.href = response.return;
          return;
        }
        this.flash('Encountered errors while saving invoice', response.errors);
      })
      .catch(error => alert('An unexpected error occurred'));
  }

  send() {
    const isValid = this.formRef.current.checkValidity();
    if (!isValid) {
      this.showValidationFeedback();
      scrollIntoView($('input:invalid')[0]);
      return;
    }
    this.hideValidationFeedback();

    let pathArray = location.pathname.split('/');
    const id = pathArray.pop();
    const path = pathArray.concat(['send', id]).join('/');

    fetch(`/api/invoices/${this.props.invoice.id}/send_and_flash`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
      },
      body: this.serializeInvoice(),
    })
      .then(response => response.json())
      .then(response => {
        if (response.status === 'success') {
          window.location.href = response.return;
          return;
        }
        this.flash('Encountered errors while sending invoice', response.errors);
      })
      .catch(error => alert('An unexpected error occurred'));
  }

  cancel() {
    location = location.href
      .split('/')
      .slice(0, -2)
      .join('/');
  }

  initializeNumericInput(number) {
    return (number === 0 && number) || number || '';
  }

  initializeLine(line) {
    return {
      ...line,
      date: this.iso8601ToLocalDate(line.date),
      rate: this.initializeNumericInput(line.rate),
      quantity: this.initializeNumericInput(line.quantity),
      isShowingRecommendations: false,
      recommendations: [],
    };
  }

  addLineGroup() {
    this.setState(state => ({
      rows: state.rows.concat([
        {
          type: 'group',
          amount: '',
          date: new Date().toISOString().split('T')[0],
          description: '',
          id: shortid.generate(),
          title: '',
          lines: [this.newLine()],
        },
      ]),
    }));
  }

  addLineToGroup(groupId) {
    this.setState(state => ({
      rows: state.rows.map(
        row =>
          row.type !== 'group'
            ? row // not a group. skip.
            : row.id !== groupId
            ? row // is a group, but not the one we want to add to. skip.
            : { ...row, lines: row.lines.concat([this.newLine()]) } // add the line to this group
      ),
    }));
  }

  addTopLevelLine() {
    this.setState(state => ({
      rows: state.rows.concat([this.newLine()]),
    }));
  }

  computeAmount(group) {
    return group.lines.reduce(
      (acc, line) => Number(line.rate) * Number(line.quantity) + acc,
      0
    );
  }

  deleteGroup(id) {
    this.setState(state => ({
      rows: state.rows.filter(row => !(row.type === 'group' && row.id === id)),
    }));
  }

  deleteLine(id) {
    if (this.state.rows.find(row => row.type == 'line' && row.id == id)) {
      this.deleteTopLevelLine(id);
    } else {
      let group = this.state.rows.find(row => {
        return row.type == 'group' && row.lines.find(line => line.id == id);
      });
      this.deleteLineFromGroup(group.id, id);
    }
  }

  deleteTopLevelLine(id) {
    this.setState(state => ({
      rows: state.rows.filter(row => row.type == 'group' || row.id !== id),
    }));
  }

  deleteLineFromGroup(groupId, lineId) {
    this.setState(state => ({
      rows: state.rows.map(row => {
        if (row.type == 'group') {
          if (row.id == groupId) {
            const newGroup = {
              ...row,
              lines: row.lines.filter(line => line.id !== lineId),
            };
            return { ...newGroup, amount: this.computeAmount(newGroup) };
          }
        }
        return row;
      }),
    }));
  }

  confirmAndDeleteInvoice(event) {
    if (!confirm('Delete this invoice?')) {
      event.preventDefault();
    }
  }

  formatLine(line) {
    return {
      ...line,
      date: line.date.toISOString().split('T')[0],
      quantity: Number(line.quantity),
      rate: Number(line.rate),
      taxable: Boolean(line.taxable),
    };
  }

  formatRow(row) {
    return row.type === 'group'
      ? {
          ...row,
          amount: Number(row.amount),
          lines: row.lines.map(line => this.formatLine(line)),
        }
      : this.formatLine(row);
  }

  newLine() {
    return this.initializeLine({
      id: shortid.generate(),
      account_name: '',
      taxable: false,
      type: 'line',
      date: new Date().toISOString().split('T')[0],
      description: '',
      equivalent_count: '',
    });
  }

  serializeInvoice() {
    return JSON.stringify({
      invoice: {
        date: this.state.date.toISOString().split('T')[0],
        due_date: this.state.due_date.toISOString().split('T')[0],
        tax_percentage: this.state.taxRate,
        bcc_email_string: this.state.bccEmailString,
        rows: this.state.rows.map(row => this.formatRow(row)),
      },
    });
  }

  setLineGroupAttribute(id, name, value) {
    this.setRowAttribute('group', id, name, value);
  }

  setLineAttribute(id, name, value) {
    if (value == null) return; // allow 0 but not null or undefined
    this.setRowAttribute('line', id, name, value);
  }

  setRowAttribute(type, id, name, value) {
    let isShowingRecommendations = false;
    let recommendations = [];
    if (name === 'description') {
      recommendations = this.getRecommendations(value);
      if (recommendations.length) {
        isShowingRecommendations = true;
      }
    }
    this.setState(state => ({
      rows: state.rows.map(row => {
        if (row.type === type && row.id === id) {
          return {
            ...row,
            [name]: value,
            isShowingRecommendations,
            recommendations,
          };
        } else if (type == 'line' && row.type === 'group') {
          const group = {
            ...row,
            lines: row.lines.map(line => {
              if (line.id === id) {
                return {
                  ...line,
                  [name]: value,
                  isShowingRecommendations,
                  recommendations,
                };
              }
              return line;
            }),
          };
          return name === 'rate' || name === 'quantity'
            ? { ...group, amount: this.computeAmount(group) }
            : group;
        }
        return row;
      }),
    }));
  }

  render() {
    return (
      <div className="invoice-editor">
        {this.state.flashContent ? (
          <div className="fixed-top mt-7">
            <Flash isError={true} onDismiss={this.dismissFlash}>
              {this.state.flashContent}
            </Flash>
          </div>
        ) : null}
        <form
          className={this.state.validationClass}
          noValidate
          ref={this.formRef}
        >
          <InvoiceHeader
            number={this.props.invoice.number}
            customer={this.props.customer}
            date={this.state.date}
            bccEmailString={this.state.bccEmailString}
            onDateChange={this.changeDate}
            onBccEmailStringChange={this.changeBccEmailString}
          />
          <InvoiceRows
            accountNames={this.props.accountNames}
            rows={this.state.rows}
            onAddLineToGroup={this.addLineToGroup}
            onGroupChange={this.setLineGroupAttribute}
            onLineChange={this.setLineAttribute}
            onDeleteGroup={this.deleteGroup}
            onDeleteLine={this.deleteLine}
            onSelectRecommendation={this.selectRecommendation}
          />
        </form>
        <InvoiceSummary
          attachments={this.props.attachments}
          dueDate={this.state.due_date}
          rows={this.state.rows}
          taxRate={this.state.taxRate}
          onChangeTaxRate={this.changeTaxRate}
          onDueDateChange={this.changeDueDate}
          onAddTopLevelLine={this.addTopLevelLine}
          onAddLineGroup={this.addLineGroup}
        />
        <InvoiceFooter
          deleteUrl={this.props.deleteUrl}
          onCancel={this.cancel}
          onSave={this.save}
          onSend={this.send}
          onDelete={event => this.confirmAndDeleteInvoice(event)}
        />
      </div>
    );
  }
}

export default InvoiceEditor;
