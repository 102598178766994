import React, { Component } from 'react';
import EquivalentCountTotal from './EquivalentCountTotal'
import InvoiceLineGroup from './InvoiceLineGroup';
import InvoiceLine from './InvoiceLine';

class InvoiceRows extends Component {
  renderRow(row) {
    if (row.type == 'group') {
      return (
        <InvoiceLineGroup
          key={`${row.type}${row.id}`}
          accountNames={this.props.accountNames}
          onAddLineToGroup={this.props.onAddLineToGroup}
          onChange={this.props.onGroupChange}
          onLineChange={this.props.onLineChange}
          onDeleteGroup={this.props.onDeleteGroup}
          onDeleteLine={this.props.onDeleteLine}
          onSelectRecommendation={this.props.onSelectRecommendation}
          group={row}
        />
      );
    } else {
      return (
        <div className="row" key={`${row.type}${row.id}`}>
          <div className="col-10">
            <InvoiceLine
              accountNames={this.props.accountNames}
              account_name={row.account_name}
              onChange={this.props.onLineChange}
              onSelectRecommendation={this.props.onSelectRecommendation}
              {...row}
            />
          </div>
          <div className="col-2">
            <div className="row">
              <div className="col">
                <div className="float-right">
                  {row.rate && row.quantity
                    ? `$${Number.parseFloat(row.rate * row.quantity).toFixed(
                        2
                      )}`
                    : ''}
                </div>
              </div>
              <div className="col pr-3 pl-3">
                <button
                  type="button"
                  className="btn btn-danger float-right"
                  onClick={() => this.props.onDeleteLine(row.id)}
                >
                  -
                </button>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <div className="float-right">
                  <EquivalentCountTotal line={row} />
                </div>
              </div>
              <div className="col">
              </div>
            </div>
          </div>
        </div>
      );
    }
  }

  render() {
    return (
      <div className="invoice-lines">
        <div className="list-group">
          {this.props.rows.map((row, i) => {
            return (
              <div
                key={`${row.type}${row.id}`}
                className={`list-group-item${
                  i % 2 == 0 ? '' : ' list-group-item-secondary'
                }`}
              >
                {this.renderRow(row)}
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

export default InvoiceRows;
