import React, { Component } from 'react';
import InvoiceLine from './InvoiceLine';
import EquivalentCountTotal from './EquivalentCountTotal';
import InputWithValidation from '../InputWithValidation';
import { asUSCurrency } from '../../utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

class InvoiceLineGroup extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const id = this.props.group.id;
    return (
      <div className="row">
        <div className="col-md-4">
          <div className="row form-group">
            <div className="col-12">
              <InputWithValidation
                className="form-control form-control-lg"
                value={this.props.group.title}
                placeholder="Title"
                required
                invalidFeedback="Please enter a title"
                onChange={event =>
                  this.props.onChange(id, 'title', event.target.value)
                }
              />
            </div>
          </div>
          <div className="row form-group">
            <div className="col-12">
              <textarea
                className="form-control"
                value={this.props.group.description}
                placeholder="Description"
                onChange={event =>
                  this.props.onChange(id, 'description', event.target.value)
                }
              />
            </div>
          </div>
        </div>
        <div className="col-md-8">
          {Array.isArray(this.props.group.lines) &&
          this.props.group.lines.length ? (
            this.props.group.lines.map(line => {
              return (
                <div className="row" key={line.id}>
                  <div className="col-md-9">
                    <InvoiceLine
                      accountNames={this.props.accountNames}
                      account_name={line.account_name}
                      onChange={this.props.onLineChange}
                      onSelectRecommendation={this.props.onSelectRecommendation}
                      {...line}
                    />
                  </div>
                  <div className="col-3">
                    <div className="row">
                      <div className="col">
                        <div className="float-right">
                          {line.rate && line.quantity
                            ? asUSCurrency(
                                Number(line.rate) * Number(line.quantity)
                              )
                            : ''}
                        </div>
                      </div>
                      <div className="col">
                        <button
                          type="button"
                          className="btn btn-danger float-right"
                          title="Delete line from group"
                          onClick={() => this.props.onDeleteLine(line.id)}
                        >
                          &minus;
                        </button>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col">
                        <div className="float-right">
                          <EquivalentCountTotal line={line} />
                        </div>
                      </div>
                      <div className="col" />
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <div className="row form-group">
              <div className="col-11 is-invalid">
                <div className="form-row align-items-center">
                  <div className="col-auto text-danger">
                    <FontAwesomeIcon icon={faExclamationTriangle} />
                  </div>
                  <div className="col">
                    <input
                      type="text"
                      readOnly
                      className="form-control-plaintext text-danger"
                      value="Please either add an invoice line or delete this group"
                    />
                  </div>
                </div>
              </div>
              <div className="col-1">
                <button
                  type="button"
                  className="btn btn-danger float-right"
                  title="Delete group"
                  onClick={() => this.props.onDeleteGroup(this.props.group.id)}
                >
                  &minus;
                </button>
              </div>
            </div>
          )}
          <div className="row">
            <div className="col-7" />
            <label className="col-2 col-form-label text-right pr-3">
              Subtotal:
            </label>
            <div className="col-3">
              <div className="row">
                <div className="col-6">
                  <input
                    type="text"
                    readOnly
                    tabIndex="-1"
                    className="form-control-plaintext text-right"
                    value={asUSCurrency(Number(this.props.group.amount))}
                  />
                </div>
                <div className="col-6">
                  <div className="mb-3">
                    <button
                      type="button"
                      className="btn btn-primary float-right"
                      title="Add line to group"
                      onClick={() =>
                        this.props.onAddLineToGroup(this.props.group.id)
                      }
                    >
                      +
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default InvoiceLineGroup;
