import React from 'react';

const Selector = ({
  options,
  onChange,
  className,
  placeHolder,
  required,
  wasValidated,
  value,
  size = 'md',
  disableDefault = true,
}) => {
  let selectClasses = ['form-control'];
  if (size === 'lg') {
    selectClasses.push('form-control-lg');
  } else if (size === 'sm') {
    selectClasses.push('form-control-sm');
  }
  if (!value) {
    selectClasses.push('text-muted');
  }
  return (
    <div className={`${className}${wasValidated ? ' was-validated' : ''}`}>
      <select
        className={selectClasses.join(' ')}
        onChange={onChange}
        value={value}
        required={required}
      >
        <option value="" disabled={disableDefault}>
          {placeHolder}
        </option>
        {options.map(option => (
          <option key={option.id} value={option.id}>
            {option.value}
          </option>
        ))}
      </select>
    </div>
  );
};

export default Selector;
