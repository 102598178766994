import React from 'react';
import PropTypes from 'prop-types';
class CustomerSelector extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filteredCustomers: this.props.customers,
      customerId: '',
    };
    this.updateFilteredList = this.updateFilteredList.bind(this);
  }

  updateFilteredList(event) {
    let filteredCustomers = this.props.customers.filter(customer => {
      return customer.name.startsWith(event.target.value);
    });
    this.setState({ filteredCustomers: filteredCustomers });
  }

  selectCustomer(customer) {
    this.setState({ customerId: customer.id });
  }

  render() {
    return (
      <React.Fragment>
        <input type="hidden" name="customer_id" value={this.state.customerId} />
        <div className="form-group row">
          <label className="col-sm-1 col-form-label font-weight-bold">
            Filter:
          </label>
          <div className="col-sm-11">
            <input
              type="text"
              className="form-control"
              onChange={this.updateFilteredList}
            />
          </div>
        </div>
        <table className="table">
          <thead>
            <tr>
              <th scope="col">Name</th>
              <th scope="col">City</th>
              <th scope="col" />
            </tr>
          </thead>
          <tbody>
            {this.state.filteredCustomers.map(customer =>
              this.renderCustomerRow(customer)
            )}
          </tbody>
        </table>
      </React.Fragment>
    );
  }

  renderCustomerRow(customer) {
    return (
      <tr key={customer.id}>
        <td>{customer.name}</td>
        <td>
          {customer.city}, {customer.state}
        </td>
        <td>
          <button
            onClick={() => this.selectCustomer(customer)}
            className="btn btn-primary btn-sm"
          >
            Create Invoice
          </button>
        </td>
      </tr>
    );
  }
}

export default CustomerSelector;
